









import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {dateFormat, timeAgo} from '@/filters';

@Component
export default class TimeAgo extends Vue {
  @Prop({default: () => new Date()}) private date!: Date;
  private dateFormat = dateFormat;
  private timeAgo = timeAgo;
}
