var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('PageTitle',[_vm._v("Clients")]),_c('v-row',[(_vm.role === 'staff')?_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.statuses,"label":"Select a status"},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.statusId),callback:function ($$v) {_vm.statusId=$$v},expression:"statusId"}})],1):_vm._e(),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clients,"search":_vm.search,"loading":_vm.loading,"item-key":"id","items-per-page":25,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm.numberFormat(item.rate / 100))+" "),_c('span',{staticClass:"currency"},[_vm._v(_vm._s(item.currency))])])]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount > 0 ? ((item.discount) + "%") : '')+" ")]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tax > 0 ? ((item.tax) + "%") : '')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","outlined":"","small":""},on:{"click":function($event){return _vm.showProjectDialog(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.iconEdit))])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.iconMore))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.archiveProject(item.id)}}},[_c('v-list-item-title',[_vm._v("Archive")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteProject(item.id)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.role === 'staff')?_c('v-btn',{attrs:{"dark":"","fab":"","bottom":"","right":"","fixed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showClientDialog('new')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconAdd))])],1):_vm._e(),(_vm.clientDialogShown)?_c('client-dialog',{attrs:{"clientId":_vm.clientId.toString()},on:{"close":function (args) { return _vm.closeClientDialog(args); },"clientId":function (newClientId) { return (this$1.clientId = newClientId); }}}):_vm._e(),_c('v-snackbar',{attrs:{"bottom":""},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }