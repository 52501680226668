<template>
  <v-dialog :value="true" persistent>
    <v-card>
      <v-card-title class="headline">Client</v-card-title>
      <v-card-text>
        <v-progress-circular
          v-if="loading === true"
          indeterminate
        ></v-progress-circular>
        <v-form v-if="loading === false" v-model="valid">
          <v-text-field
            :rules="rulesName"
            v-model="client.name"
            label="Name"
            required
          />
          <v-row>
            <v-col cols="6" sm="8" md="10">
              <v-text-field
                :rules="rulesRequiredNumber"
                v-model="client.rate"
                label="Hourly Rate"
                placeholder="60.00"
                prefix="$"
                required
              />
            </v-col>
            <v-col cols="6" sm="4" md="2">
              <v-autocomplete
                :items="[
                  {text: 'CAD', value: 'CAD'},
                  {text: 'USD', value: 'USD'},
                ]"
                :rules="rulesRequired"
                v-model="client.currency"
                label="Currency"
                required
              />
            </v-col>
          </v-row>
          <v-text-field
            :rules="rulesRequiredNumber"
            v-model="client.tax"
            label="Tax"
            required
            suffix="%"
          />
          <v-text-field
            :rules="rulesName"
            v-model="client.terms"
            label="Terms"
            required
          />
          <v-text-field
            :rules="rulesRequiredNumber"
            v-model="client.discount"
            placeholder="10"
            hint="Enter a discount percentage"
            label="Discount (%)"
            required
            suffix="%"
          />
          <v-autocomplete
            :items="[
              {text: 'Monthly', value: 'monthly'},
              {text: 'Semi-monthly', value: 'semi-monthly'},
            ]"
            :rules="rulesRequired"
            v-model="client.billingCycle"
            hint="How often should we bill this
          client"
            label="Choose a billing cycle"
            required
          />
          <v-textarea
            :rules="rulesRequired"
            v-model="client.billingAddress"
            label="Billing Address"
            required
          />
        </v-form>
        <v-alert v-if="message" type="error">{{ message }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel()" :disabled="saving" x-large>Cancel</v-btn>
        <v-btn
          @click="save()"
          :disabled="!valid || saving"
          :loading="saving"
          color="primary"
          x-large
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ClientDialog',
  components: {},
  computed: {
    ...mapGetters('auth', ['auth']),
    ...mapGetters('clients', {findClient: 'find'}),
  },
  async created() {
    // was a clientId passed in?
    if (this.clientId > 0) {
      await this.loadClient(this.clientId);
      this.client = {...this.findClient(this.clientId)};
      this.loading = false;
    } else {
      // must be a new client
      // create new client and let the parent component know about the clientId change so it can pass in the new clientId
      this.client = {...(await this.addClient())};
      this.$emit('clientId', this.client.id);
      this.loading = false;
    }
  },
  data: () => {
    return {
      loading: true,
      message: null,
      client: null,
      rulesName: [
        v => !!v || 'Name is required',
        v => (v && v.length > 3) || 'Name is too short',
      ],
      rulesRequired: [v => !!v || 'This field is required'],
      rulesRequiredNumber: [
        v => !!v || 'This field is required',
        v => (v && !isNaN(v)) || 'This field must be a number',
      ],
      saving: false,
      valid: false,
    };
  },
  methods: {
    ...mapActions('clients', {
      loadClient: 'loadById',
      addClient: 'add',
      removeClient: 'remove',
      saveAndPersistClient: 'saveAndPersist',
    }),
    async save() {
      this.saving = true;
      try {
        const client = {...this.client};
        client.rate = client.rate * 100;
        this.client = await this.saveAndPersistClient(client);
        this.$emit('close', {message: 'Your client has been saved.'});
      } catch (e) {
        console.error(e, e.response);
        this.message = e.response.data.errors[0].detail;
      }
      this.saving = false;
    },
    async cancel() {
      await this.removeClient(this.clientId);
      this.$emit('close', {message: null});
    },
  },
  props: {
    clientId: {type: String, default: null},
  },
};
</script>

<style scoped lang="scss"></style>
