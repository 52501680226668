











































































































































































































































































































































































































import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import {invoiceModule} from '@/store/modules/invoiceModule';
import {EditableFieldSaveEvent, InvoiceResource, Snackbar} from '@/interfaces';
import {
  mdiCalendar,
  mdiCheckDecagram,
  mdiContentCopy,
  mdiDelete,
  mdiDownload,
  mdiPencil,
} from '@mdi/js';
import {n2br, numberFormat} from '@/filters';
import {InvoiceType, ResourceType, Role} from '@/enums';
import TimeAgo from '@/components/ui/TimeAgo.vue';
import Spinner from '@/components/ui/Spinner.vue';
import {VueShowdown} from 'vue-showdown/dist/vue-showdown.esm';
import {actionApi, invoiceApi} from '@/api';
import {downloadFile} from '@/utility';
import {authModule} from '@/store/modules';
import EditableField from '@/components/ui/EditableField.vue';

@Component({
  components: {EditableField, Spinner, TimeAgo, VueShowdown},
})
export default class InvoiceView extends Vue {
  private allowEdit = false;
  private cancelling = false;
  private confirming = false;
  private downloading = false;
  private iconCopy = mdiContentCopy;
  private iconDelete = mdiDelete;
  private iconEdit = mdiPencil;
  private iconCalendar = mdiCalendar;
  private iconVerify = mdiCheckDecagram;
  private iconDownload = mdiDownload;
  private menuDate = false;
  private menuDueDate = false;
  private roles = Role;
  private savingId: string | null = null;
  snackbar: Snackbar = {visible: false, message: ''};

  n2br = n2br;
  numberFormat = numberFormat;

  get loading(): boolean {
    return invoiceModule.loading;
  }

  get saving(): boolean {
    return invoiceModule.saving;
  }

  get headers(): Record<string, string>[] {
    return [
      {
        text:
          this.invoice.attributes.type === InvoiceType.Service
            ? 'DATE'
            : 'ITEM',
        value:
          this.invoice.attributes.type === InvoiceType.Service
            ? 'date'
            : 'item',
        cellClass: 'text-no-wrap date',
      },
      {
        text: 'DESCRIPTION',
        value: 'description',
      },
      {
        text: 'QTY',
        value: 'quantity',
        align: 'end',
        cellClass: 'text-no-wrap',
      },
      {
        text: 'RATE',
        value: 'rate',
        align: 'end',
        cellClass: 'text-no-wrap',
      },
      {
        text: 'AMOUNT',
        value: 'amount',
        align: 'end',
        cellClass: 'text-no-wrap',
      },
    ];
  }
  get invoice(): InvoiceResource {
    return invoiceModule.find(this.$route.params.invoiceId);
  }

  get role(): Role | null {
    return authModule.role;
  }

  async created(): Promise<void> {
    await invoiceModule.loadById(this.$route.params.invoiceId);
  }

  async getPdf(invoiceId: string) {
    this.downloading = true;
    try {
      const invoice = await invoiceApi.getRaw(invoiceId, {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      });
      //console.log(invoice);
      // const file = new File(invoice, 'invoice.pdf');
      // console.log(file);
      downloadFile(invoice, `Invoice ${invoiceId} from John Clark.pdf`);
    } catch (err) {
      console.error(err);
      this.snackbar.visible = true;
      this.snackbar.message =
        'An error occurred while downloading this invoice.';
    } finally {
      this.downloading = false;
    }
  }

  async confirmInvoice() {
    this.confirming = true;
    const invoiceId = this.$route.params.invoiceId;
    try {
      await actionApi.post({
        type: ResourceType.Action,
        attributes: {
          action: 'confirm-invoice',
          invoiceId: invoiceId,
        },
      });
    } catch (err) {
      console.error(err);
      this.snackbar.visible = true;
      this.snackbar.message =
        'An error occurred while confirming this invoice.';
    } finally {
      this.confirming = false;
    }
  }

  async cancelInvoice() {
    this.cancelling = true;
    const invoiceId = this.$route.params.invoiceId;
    try {
      await actionApi.post({
        type: ResourceType.Action,
        attributes: {
          action: 'cancel-invoice',
          invoiceId: invoiceId,
        },
      });
    } catch (err) {
      console.error(err);
      this.snackbar.visible = true;
      this.snackbar.message =
        'An error occurred while cancelling this invoice.';
    } finally {
      this.cancelling = false;
    }
  }

  async save({id}: EditableFieldSaveEvent) {
    // in this case the id is not a record id and just an identifier for the
    // loading indicator
    console.log('save', id);
    this.savingId = id;
    await invoiceModule.save(this.invoice.id);
    this.savingId = null;
  }

  downloadHledger() {
    const discount = this.invoice.attributes.discount;

    const invoiceNumber = this.invoice.id;
    const date = this.invoice.attributes.date;
    const client = this.invoice.attributes.client.name;
    const currency = this.invoice.attributes.currency;
    const tax = `-${numberFormat(this.invoice.attributes.tax / 100)}`.padStart(
      10,
      ' '
    );
    const subtotal = `-${numberFormat(
      this.invoice.attributes.subtotal / 100
    )}`.padStart(10, ' ');
    const total = numberFormat(this.invoice.attributes.total / 100).padStart(
      10,
      ' '
    );
    const discountLine =
      discount > 0
        ? `\n    Income:Business                        ${numberFormat(
            discount / 100
          ).padStart(10, ' ')} ${currency}`
        : '';

    const hledger = `${date} (${invoiceNumber}) ${client}
    Income:Business                        ${subtotal} ${currency}${discountLine}
    Liabilities:HST                        ${tax} ${currency}
    Assets:Business:Accounts Receivable    ${total} ${currency}
      `;
    console.log(hledger);
    return hledger;
  }
}
