



































































































import ClientDialog from '../components/ClientDialog.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import {ClientAttributes, SelectItem, Snackbar} from '@/interfaces';
import {authModule, clientModule} from '@/store/modules';
import {mdiDotsVertical, mdiMagnify, mdiPencil, mdiPlus} from '@mdi/js';
import {numberFormat} from '@/filters';
import PageTitle from '@/components/PageTitle.vue';
import {Role} from '@/enums';

@Component({
  components: {
    PageTitle,
    ClientDialog,
  },
})
export default class Clients extends Vue {
  private clientDialogShown = false;
  private clientId: string | null = null;
  private headers = [
    {text: 'Name', value: 'name'},
    {text: 'Rate', value: 'rate'},
    {text: 'Tax', value: 'tax'},
    {text: 'Terms', value: 'terms'},
    {text: 'Discount', value: 'discount'},
    {align: 'center', text: 'Actions', value: 'action'},
  ];
  private iconAdd = mdiPlus;
  private iconEdit = mdiPencil;
  private iconMore = mdiDotsVertical;
  private numberFormat = numberFormat;
  private search = '';
  private selected = [];
  private snackbar: Snackbar = {visible: false, message: ''};
  statusId = 0;
  statuses: SelectItem[] = [
    {text: 'Active', value: 0},
    {text: 'Archived', value: 1},
  ];

  get clients(): ClientAttributes[] {
    return clientModule.list.map(client => client.attributes);
  }

  get loading(): boolean {
    return clientModule.loading;
  }

  get role(): Role | null {
    return authModule.role;
  }

  created() {
    this.update();
  }

  closeClientDialog(message: string): void {
    this.clientDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteClient(id: string): Promise<void> {
    const client = clientModule.find(id);
    if (
      confirm(
        `Are you sure you want to delete the client: ${client.attributes.name}`
      )
    ) {
      await clientModule.delete(id);
      this.snackbar.visible = true;
      this.snackbar.message = 'Client deleted';
    }
  }

  showClientDialog(id: string): void {
    this.clientId = String(id);
    this.clientDialogShown = true;
  }

  async update() {
    await clientModule.loadMulti({
      filters: {
        archived: String(this.statusId),
      },
    });
  }
}
